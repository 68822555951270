import { render, staticRenderFns } from "./editshare.vue?vue&type=template&id=695d95ab&scoped=true"
import script from "./editshare.vue?vue&type=script&lang=js"
export * from "./editshare.vue?vue&type=script&lang=js"
import style0 from "./editshare.vue?vue&type=style&index=0&id=695d95ab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "695d95ab",
  null
  
)

export default component.exports