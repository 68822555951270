<template>
  <div>
    <div v-if="dis == 99">
      <success></success>
    </div>
    <div v-else-if="dis == 3" class="mcenter mt45vh">
      <div class="border p10">
        <open-app :ids="ids"></open-app>
      </div>
    </div>
    <div v-else-if="dis == 1">
      <add-list :group="formlist" :ruleForm="grouplist" :shareId="id" :companyId="companyId" @changedis="changedis"></add-list>
    </div>
    <div v-else class="mt15">
      <div v-if="datalist.title!=null">
        <van-row>
          <van-col span="16" offset="1">{{datalist.title}}</van-col>
          <van-col span="7" class="mright">
<!--            <span class="font13 colchose paddzy8" @click="toApp">去APP查看</span>-->
            <div>
              <open-app :ids="ids"></open-app>
            </div>
          </van-col>
        </van-row>
<!--        <div class="line"></div>-->
        <van-form @submit="onSubmitForm" @failed="failSub">
          <div v-for="(item,key) in datalist.groups" :key="key" class="mt2vh">
            <div v-if="isShowStat(item,ruleForm)">
              <div>
                <van-row class="mb1vh bgf2 padd8">
                  <van-col span="19" offset="1">{{item.title}}</van-col>
                  <van-col span="4" v-if="item.type == 'list'" @click="addData(item)" class="mycursor">
                    <van-icon name="add" color="#1989F0"/><span style="color: #1989f0">添加</span>
                  </van-col>
                </van-row>
              </div>
              <div v-if="item.type == 'data'">
                <div >
                  <form-display :group="item.fields" :ruleForm="ruleForm" :name="'form'" :tableData="tableData"
                                :tlist="datalist" :shareId="id" :companyId="companyId" @getForms="getForms"></form-display>
                </div>
              </div>
              <div v-if="item.type == 'list' && listinfo.length != 0">
                <div >
                  <edit-list :listinfo="listinfo" :items="item" :ruleForm="ruleForm" :tableData="tableData"
                             :tlist="datalist" :shareId="id" :companyId="companyId" @getLists="getLists"></edit-list>
                </div>
              </div>
            </div>
<!--            <div class="lines"></div>-->
          </div>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import {getDicVal, getEditData, saveShareData} from "@/api/api";//
import {Toast} from "vant";
import addList from "@/components/addList";
import formDisplay from "@/components/formDisplay";
import mixins from '@/mixins';
//import mInput from "@/mixins/minput";
import success from "@/components/success";
import openApp from "@/components/openApp";
import editList from "@/components/editList";

export default {
  name: "editshare",
  mixins: [mixins],
  data(){
    return{
      imgUrl: "http://bullpm-1253367923.cos.ap-chengdu.myqcloud.com",
      imgCompress: "?imageMogr2/thumbnail/!70p",
      id: "",
      sign: "",
      datalist: [],
      datainfo: {},
      formlist: [],
      grouplist: [],
      dis: 0,
      listinfo: [],
      ruleForm: {},
      list:{
        field:""
      },
      currentDate: new Date(),
      currentTime:"",
      currentDateTime: new Date(),
      companyId: "",
      datadef: {
        visible: null,//可见权限
        inherit: null,
      },
      visible: true,
      diclist: [],
      ids: {
        dataId: "",
        appId: "",
        companyId: "",
        upDataId: "",
        FiledStatus: "",
      },
      tableData: {file: []},
    }
  },
  components:{
    addList,
    formDisplay,
    success,
    openApp,
    editList,
  },
  mounted() {
    this.getData();
    this.getInfo();
    //this.getdic();
  },
  methods: {
    getData(){
      /*let href = ""
      if (href.indexOf("id") != -1) {
        this.id = qs.parse(href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(href.split("#")[0].split("?")[1]).sign;
      }*/
      if (window.location.href.indexOf("id") != -1){
        this.id = qs.parse(window.location.href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(window.location.href.split("#")[0].split("?")[1]).sign
      }
      if (this.id) {
        getEditData(this.id).then(res => {
          //console.log("share",res);
          if (res != null && res.data != null && res.data.content != null) {
            //console.log('contents', res);
            let contents = res.data.content;
            //this.getProForms(contents);
            this.diclist = [];
            this.companyId = contents.form.companyId
            this.datalist = JSON.parse(contents.form.formDef);
            console.log('form555', this.datalist);
            if (!this.isEmptyData(contents.data.dataId)){
              this.ids = {
                dataId: contents.data.dataId,
                appId: contents.data.appId,
                FiledStatus: contents.data.FiledStatus,
                upDataId: contents.data.upDataId,
              }
            }
            if (!this.isEmptyData(contents.form.companyId)){
              this.ids.companyId = contents.form.companyId;
            }
            if (!this.isEmptyData(contents.form.dataDef)){
              let def = JSON.parse(contents.form.dataDef);
              if (!this.isEmptyData(def.viewData.visible)){
                this.datadef.visible = def.viewData.visible;
                this.visible = true;
                //console.log("d6666",this.visible);
              }else {
                this.visible = false;//没有可见可编辑配置
                //console.log("datadef",this.visible);
              }
            }
            let groups = this.datalist.groups;
            if (groups!=null&&groups!=''&&groups.length!=0){
              this.setRuleForm(this.list);
            }
            let dic = [];
            if(contents.data!=null&&contents.data!=''){
              this.datainfo = contents.data;
              for (let i in this.datalist.groups) {
                let item = this.datalist.groups[i];
                for (let j in item.fields) {
                  let items = item.fields[j];
                  //是否可见、可编辑
                  items.visible = true;
                  items.editdis = false;
                  if (this.visible == true && this.datadef.visible != null ){
                    if (!this.isEmptyData(this.datadef.visible[items.name])){
                      if ( this.datadef.visible[items.name].indexOf("visible") === -1){
                        items.visible = false;//不可见
                      }
                      if (this.datadef.visible[items.name].indexOf('edit') === -1){
                        items.editdis = true;//不可编辑
                      }else {
                        items.editdis = !items.editable;//设计表单时控制的可编辑状态
                      }
                    }else {
                      items.visible = false;
                    }
                  }else {
                    items.editdis = !items.editable;//设计表单时控制的可编辑状态
                  }
                  if (items.type == 'img') {//当有img时，会组装img路径如下所示
                    if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                      this.datainfo[items.name].list = [];
                      for (let k in this.datainfo[items.name]) {
                        let d = this.datainfo[items.name][k];
                        if (d.fileName != null && d.fileName != '') {
                          this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName + this.imgCompress);
                        }
                      }
                    }
                  }
                  if (items.type == 'file') {
                    if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                      this.datainfo[items.name].list = [];
                      for (let k in this.datainfo[items.name]) {
                        let d = this.datainfo[items.name][k];
                        if (d.fileName != null && d.fileName != '') {
                          this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName);
                        }
                      }
                    }
                  }
                  if (items.source.type == 'dic' && items.source.from != ''){
                    dic.push(items.source.from);
                  }
                }
                if (item.type == 'list'){
                  for (let k in item.fields){
                    let ite = item.fields[k];
                  }
                  this.tableData[item.name] = this.datainfo[item.name];
                }
              }
              const dictype = new Set(dic)
              let dics = [...dictype]

              if (dics.length > 0){
                for (let n in dics){
                  this.getdic(dics[n]);
                }
              }
              //console.log('diclist', this.diclist);
              delete this.tableData.file;
              /*console.log("tabledata",this.tableData);
              console.log('form9999', this.datalist);*/
              this.listinfo = [];
              this.setRuleForm(this.list);
            }
          } else {
            //console.log("500",typeof res.data.errorCode)
            if (res.data.errorCode == "500"){
              Toast.fail(res.data.errorMsg);
                //this.toApp();
                this.dis = 3;
                /*if (res.data.errorMsg == "数据不存在！"){
              }else {

              }*/
            }else {
              Toast.fail(res.data.errorMsg);
            }
          }
        })
      }
    },
    //赋值ruleForm
    setRuleForm(vdata){
      //this.listinfo = [];
      let data={};//
      if (this.datalist.groups){
        for(let i in this.datalist.groups){
          let group=this.datalist.groups[i];
          //data[group.name]=group.title;
          if (group.fields){
            if(group.type=="data"||group.type == 'list'){
              for (let j in group.fields){
                let item = group.fields[j];
                if (item.type == "date") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDate(this.currentDate);
                    //console.log('1',data[item.name])
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "time") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.currentTime;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "datetime") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDateTime(this.currentDateTime);
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == 'txt' || item.type == 'decimal' || item.type == 'number' || item.type == 'phone'){
                  if (item.source.default !=null && item.source.default !=''){
                    data[item.name] = item.source.default;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else {
                  data[item.name]=vdata[item.name];
                }
                if (this.datainfo!=null&&this.datainfo!=''&&this.datainfo.dataId!=null){
                  let k = item.name;
                  if (item.type == "date"){
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "select") {
                    if (this.datainfo[k]!=null&&this.datainfo[k]!=''){
                      if (item.source.data.length == 0 && item.source.type != 'dic'){
                        data[item.name] = this.datainfo[k];
                      }else {
                        data[item.name] = this.datainfo[k].value;
                      }
                    }
                  }else if (item.type == "mutiselect") {
                    if (item.source.data.length == 0 && item.source.type != 'dic'){
                      data[item.name] = this.datainfo[k];
                    }
                    else {
                      data[item.name] = [];
                      for (let aa in this.datainfo[k]){
                        data[item.name].push(this.datainfo[k][aa].value)
                      }
                    }
                  }else if (item.type == "time") {
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "datetime") {
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "img" || item.type == "file"){
                    data[item.name] = [];
                    if (!this.isEmptyData(this.datainfo[k])){
                      data[item.name] = this.datainfo[k];
                    }
                  }else {
                    data[item.name] = this.datainfo[k];
                  }
                }
              }
            }
          }
          if (group.type == 'list' ){
            this.listinfo.push({name: group.name,data: []})
            if (this.datainfo[group.name]){
              let index = this.listinfo.findIndex(x => x.name == group.name);
              if (index !== -1){
                this.listinfo[index].data = this.datainfo[group.name] ;
              }
            }
            this.listinfos = this.listinfo;
            localStorage.removeItem('listinfo')
            const data = this.listinfos;
            const datas = JSON.stringify(data);
            localStorage.setItem('listinfo',datas);
          }
        }
      }
      this.ruleForm = data;
      //this.errmsg = data;
      //console.log("shujusss",this.ruleForm);
    },
    getDefaultval(items){
      let data = {
        field: ""
      }
      for (let i in items.fields){
        let item = items.fields[i];
        if (item.type == "date") {
          if (item.source.defaultype == '1'){
            data[item.name] = item.source.default;
          }else if (item.source.defaultype == '0'){
            data[item.name] = this.formatDate(this.currentDate);
            //console.log('1',data[item.name])
          }else {
            data[item.name]="";
          }
        }else if (item.type == "time") {
          if (item.source.defaultype == '1'){
            data[item.name] = item.source.default;
          }else if (item.source.defaultype == '0'){
            data[item.name] = this.currentTime;
          }else {
            data[item.name]="";
          }
        }else if (item.type == "datetime") {
          if (item.source.defaultype == '1'){
            data[item.name] = item.source.default;
          }else if (item.source.defaultype == '0'){
            data[item.name] = this.formatDateTime(this.currentDateTime);
          }else {
            data[item.name]="";
          }
        }else if (item.type == 'txt' || item.type == 'decimal' || item.type == 'number' || item.type == 'phone'){
          if (item.source.default !=null && item.source.default !=''){
            data[item.name] = item.source.default;
          }else {
            data[item.name]="";
          }
        }else {
          data[item.name]="";
        }
      }
      delete data.field;
      return data;
    },
    addData(item){
      //console.log("item",item);
      this.formlist = [];
      this.formlist = item;
      //this.setRuleForm(this.list,'add');
      this.nowkey = null;
      this.grouplist = {};
      this.grouplist = this.getDefaultval(item);
      //console.log("092834 - goadd",this.ruleForm)
      this.dis = 1;
    },
    //获取添加的列表的数据
    getInfo(){
      if (localStorage.getItem('listinfo')!=null && localStorage.getItem('listinfo')!=undefined){
        this.listinfo = JSON.parse(localStorage.getItem('listinfo'));
        this.listinfos = JSON.parse(localStorage.getItem('listinfo'));
        //console.log("get---listinfo123",this.listinfo)
        this.getlists();
      }
      //console.log("list111 - final",this.listinfo);
    },
    toUpdate(item,ke,itt){
      this.formlist = [];
      this.formlist = item;
      this.grouplist = {};
      let i = itt;
      //console.log("itemsss",itt)
      for (let z in item.fields){
        let its = item.fields[z];
        if (its.type == 'select'){
          if (its.source.data.length != 0){
            if (i[its.name]){
              i[its.name] = i[its.name].value;
            }
          }else {
            if (i[its.name]){
              i[its.name] = i[its.name];
            }
          }
        }else if (its.type == 'mutiselect'){
          if (i[its.name].length != 0){
            if (its.source.data.length != 0){
              for (let a in i[its.name]){
                let it = i[its.name][a];
                i[its.name][a] = it.value;
              }
            }else {
              for (let a in i[its.name]){
                let it = i[its.name][a];
                i[its.name][a] = it;
              }
            }
          }
        }
      }
      this.grouplist = i;
      //console.log('iiii',i)
      let da = {key: ke}
      this.$store.commit('addlist/CLEAR_ADDINFO');
      this.$store.commit('addlist/SET_ADDINFO',da);
      //console.log("thke",da)
      this.dis = 1;
    },
    getlists(){
      for (let n in this.listinfo){
        let info = this.listinfo[n];
        for (let k in this.datalist.groups){
          let group = this.datalist.groups[k];
          if (group.type == 'list' && group.name == info.name){
            for (let j in info.data){
              let its = info.data[j];
              for (let k in group.fields){
                let sou = group.fields[k];
                if (sou.type == 'select'){
                  if (its[sou.name]){
                    let da = its[sou.name];
                    if (sou.source.data.length != 0){
                      let dat = sou.source.data;
                      for (let m in dat){
                        if (da == dat[m].value){
                          its[sou.name]= dat[m];
                        }
                      }
                    }
                    if (sou.source.type == 'dic' && sou.source.from != ''){
                      //判断是否有配置条件，若有如下：
                      if (!this.isEmptyData(sou.source.conditions) && sou.source.conditions.length > 0){
                        let dic = sou.source.conditions[0].field;
                        if (!this.isEmptyData(this.ruleForm[dic])){
                          let data = {
                            dicType: sou.source.from,
                            pdicCode: this.ruleForm[dic].value
                          }
                          getDicVal(data).then(res => {
                            if (res != null && res.data != null && res.data.content != null){
                              if (res.data.content.length > 0){
                                let con = res.data.content;
                                for (let l in con){
                                  if (this.ruleForm[sou.name] == con[l].value){
                                    this.ruleForm[sou.name] = {label: con[l].label,value: con[l].value};
                                  }
                                }
                              }
                            }
                          })
                        }
                      }else {
                        for (let i in this.diclist) {
                          let it = this.diclist[i];
                          if (it.type == sou.source.from) {
                            for (let j in it.list) {
                              if (da == it.list[j].value) {
                                its[sou.name] = {label: it.list[j].label, value: it.list[j].value};
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }else if (sou.type == 'mutiselect'){
                  let datas = [];
                  if (its[sou.name]){
                    for (let p in its[sou.name]){
                      let da = its[sou.name][p];
                      if (da.label){
                        da = its[sou.name][p];
                      }else {
                        if (sou.source.data.length != 0){
                          let dat = sou.source.data;
                          for (let d in dat){
                            if (da == dat[d].value){
                              datas.push(dat[d]);
                            }
                          }
                        }
                        if (sou.source.type == 'dic' && sou.source.from != ''){
                          for (let i in this.diclist){
                            let it = this.diclist[i];
                            if (it.type == sou.source.from){
                              for (let j in it.list){
                                if (da == it.list[j].value){
                                  datas.push({label: it.list[j].label, value: it.list[j].value});
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    its[sou.name] = datas;
                  }
                }
                if (sou.grouptype == 'number' || sou.grouptype == 'calc'){
                  if (!this.isEmptyData(its[sou.name])){
                    its[sou.name] = Number(its[sou.name]);
                  }
                }
              }
            }
          }
        }
      }
    },
    async getdata(name,data){
      return new Promise((resolve,reject) => {
        getDicVal(data).then(res => {
          if (res != null && res.data != null && res.data.content != null){
            if (res.data.content.length > 0){
              let con = res.data.content;
              for (let l in con){
                if (this.ruleForm[name] == con[l].value){
                  let data = {label: con[l].label,value: con[l].value};
                  console.log("4444444",data)
                  resolve(data);
                  //this.ruleForm[name] = {label: con[l].label,value: con[l].value};
                }
              }
            }
          }
        })
      })
    },
    async onSubmitForm(){//提交表单
      this.getlists();
      //console.log("ruleForm----",this.ruleForm);
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        if (grou.type == 'list'){
          this.ruleForm[grou.name] = [];
          for (let i in this.listinfo){
            let its = this.listinfo[i];
            if (its.name == grou.name){
              if (its.data.length > 0){
                this.ruleForm[grou.name] = its.data;
              }
            }
          }
          //this.ruleForm[grou.name] = this.listinfo;
          for (let l in grou.fields){
            let lit = grou.fields[l];
            delete this.ruleForm[lit.name];
          }
        }else {
          for(let n in grou.fields){
            let items= grou.fields[n];
            if (items.type=="select"){
              if (this.ruleForm[items.name]){
                let valu = this.ruleForm[items.name];
                let datas = items.source.data
                if (datas!=null&&datas.length>0){
                  for (let m in datas){
                    let val=datas[m];
                    if (val.value==valu){
                      this.ruleForm[items.name]=val;
                    }
                  }
                }
                if (items.source.type == 'dic' && items.source.from != ''){
                  //判断是否有配置条件，若有如下：
                  if (!this.isEmptyData(items.source.conditions) && items.source.conditions.length > 0){
                    let dic = items.source.conditions[0].field;
                    if (!this.isEmptyData(this.ruleForm[dic])){
                      let data = {
                        dicType: items.source.from,
                        pdicCode: this.ruleForm[dic].value != undefined ? this.ruleForm[dic].value : this.ruleForm[dic]
                      }
                      this.ruleForm[items.name] = await this.getdata(items.name,data);
                      console.log("5555555",this.ruleForm[items.name])
                      /*getDicVal(data).then(res => {
                        if (res != null && res.data != null && res.data.content != null){
                          if (res.data.content.length > 0){
                            let con = res.data.content;
                            for (let l in con){
                              if (this.ruleForm[items.name] == con[l].value){
                                this.ruleForm[items.name] = {label: con[l].label,value: con[l].value};
                                //console.log("4444444",this.ruleForm[items.name])
                              }
                            }
                          }
                        }
                      })*/
                    }
                  }else {
                    for (let i in this.diclist){
                      let it = this.diclist[i];
                      if (it.type == items.source.from){
                        for (let j in it.list){
                          if (this.ruleForm[items.name] == it.list[j].value){
                            this.ruleForm[items.name] = {label: it.list[j].label,value: it.list[j].value};
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            if (items.type=="mutiselect"){
              let values = [];
              if (this.ruleForm[items.name]){
                for(let i in this.ruleForm[items.name]){
                  let val = this.ruleForm[items.name][i];
                  if (items.source.data!=null&&items.source.data!=''&&items.source.data.length!=0){
                    for (let j in items.source.data){
                      let vall = items.source.data[j];
                      if (val == vall.value){
                        values.push(vall) ;
                      }
                    }
                  }
                  if (items.source.type == 'dic' && items.source.from != ''){
                    for (let i in this.diclist){
                      let it = this.diclist[i];
                      if (it.type == items.source.from){
                        for (let j in it.list){
                          if (val == it.list[j].value){
                            values.push({label: it.list[j].label, value: it.list[j].value});
                          }
                        }
                      }
                    }
                  }
                }
              }
              this.ruleForm[items.name] = values
            }
            if (items.grouptype == "number"||items.grouptype == "calc"){
              if (!this.isEmptyData(this.ruleForm[items.name])){
                this.ruleForm[items.name] = Number(this.ruleForm[items.name])
              }
            }
          }
        }
      }
      setTimeout(() => {
        console.log("ruleForm123",this.ruleForm);
        saveShareData(this.id,this.ruleForm).then(res => {
          if (res!=null&&res.data!=null&&res.data.content!=null){
            if (res.data.content==1){
              Toast.success('提交成功');
              //this.lowering(this.ruleForm);
              //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5ef0e4fab3c1525d&redirect_uri=https%3A%2F%2Fsixu.work%2Ftask&response_type=code&scope=snsapi_userinfo&state="+this.state+"#wechat_redirect"
              localStorage.removeItem('listinfo');
              this.dis = 99;
            }
          }else {
            Toast.fail(res.data.errorMsg);
            this.lowering(this.ruleForm);
          }
        })
      },500)
    },
    lowering(da){
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        for(let n in grou.fields){
          let items= grou.fields[n];
          if (items.type=="select"){
            //console.log("111",da);
            if (da[items.name] != null && da[items.name] != ''){
              //console.log("333")
              da[items.name] = da[items.name].value
              //console.log("aaa",da[items.name])
            }
          }
          if (items.type=="mutiselect"){
            let values = [];
            if (da[items.name]){
              for(let i in da[items.name]){
                values.push(da[items.name][i].value);
              }
            }
            da[items.name] = values
          }
        }
      }
      //console.log("daaaa",da)
      //return da;
    },
    failSub(){
      Toast("请检查必填项是否全部填写！")
    },
    changedis(d){
      this.dis = d;
      console.log("add-back",this.ruleForm);
      this.getInfo();
    },
    getdic(val){
      let data = {
        dicType: val,
      }
      getDicVal(data).then(res => {
        //console.log("dic",res);
        if (res != null && res.data != null && res.data.content != null){
          if (res.data.content.length > 0){
            this.diclist.push({type: val, list: res.data.content});
          }
        }
      })
    },
    toApp(){
      //let url = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.fourbull.pm';
      let url = "sixu://sixu.work?dataId="+this.ids.dataId+"&appId="+this.ids.appId+"&companyId="+this.ids.companyId+"&FiledStatus="+this.ids.FiledStatus+"&upDataId="+this.ids.upDataId;
      //console.log("url",url)
      window.location.href = url;
    },
    getLists(data,name,k,items){
      for (let i in this.listinfo){
        let its = this.listinfo[i];
        if(its.name == items.name){
          its = data;
        }
      }
      localStorage.removeItem('listinfo');
      console.log("listinfo-edit",this.listinfo);
      localStorage.setItem('listinfo',JSON.stringify(this.listinfo));
    },
    getForms(data,name){
      this.ruleForm[name] = data;
      //console.log("file---",this.ruleForm[name]);
      //console.log("ruleForm--",this.ruleForm);
    }
  },
}
</script>

<style scoped>
.wid{width: 100%;}
.mt5{margin-top: 5px;}

.mt2vh{margin-top: 2vh;}
.mt10{margin-top: 10px;}
.ml15{margin-left: 15px;}
.mb20{margin-bottom: 2vh;}
.mt15{margin-top: 15px;}
.mb1vh{margin-bottom: 1vh;}
.font13{font-size: 13px;}
.line1{border-top: #f2f2f2 solid 1px;width: 88vw;margin: 8px auto;}
.lines{border-top: #f2f2f2 solid 1px;width: 92vw;margin: 20px auto;}
.mr{margin-right: 18vw;}
.mr10{margin-right: 10px;}
.mright{text-align:right;}
.mcenter{text-align: center;}
.btn{text-align: center;margin: 4vh auto;}
.txt{vertical-align: middle;}
/deep/ .van-dropdown-menu{box-shadow:0 0 0 #f2f2f2!important;}
.col19{color: #1989f0;}
.bor{border: 1px solid #C0C4CC;border-radius: 5px;}
.linebot{border-bottom: 1px dashed #C0C4CC;width: 90%;margin: 0 auto;}
</style>